import React, { useEffect, useState, useContext, useRef } from 'react'
import './searchItems.css'
import { addSlashes, getParameterByName } from '../../../utils/commonFunctions'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import SearchProductList from '../../../Component/ProductCard/product'
import Layout from '../Layout'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FilterPanel from '../../../Product/components/organisms/FilterPanel'
import Loaders from '../../../Product/components/molecules/Loaders'
import { Button, Drawer, styled } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import BuyerContext from '../../../Product/context/buyer/buyerContext'
import { useLocation } from 'react-router-dom'
import { Pagination } from '@material-ui/lab'
import { load } from 'dotenv'
import { messageHandler } from '../../../Product/common/socketHandler'
import { socket, socketAuction } from '../../../Product/common/socket'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import UserActivity from '../../../utils/CommonFunctionality/UserActivity'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})
const usePaginationStyle = makeStyles((theme) => ({
    root: {
        width: 'auto',
        '& .MuiPagination-ul': {
            margin: '0 !important',
        },
    },
}))

const AuctionSearchComponent = (props) => {
    // const [cookies, setCookie] = useCookies(['user_session'])
    const location = useLocation()
    const buyerContext = useContext(BuyerContext)
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const history = useHistory()
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const { buyer_allproducts, getAllBuyerProducts, product_view, getViewProduct } = buyerContext

    const { search_allproducts, getAllSearchProducts, getAllSearchItems } = productContext
    const { setAlert } = alertContext
    const { user, isAuthenticated } = authContext
    const classes = useStyles()
    const classes2 = usePaginationStyle()
    const [searchbar] = useState(new URLSearchParams(window.location.search.substring(1)).get('q'))
    const [isLoading, setLoading] = useState(true)
    const [viewProduct, setViewProduct] = useState([])
    const [locationfillter, setLocation] = useState([])
    const [page, setPage] = useState(1)
    const [totalViewProduct, setTotalViewProduct] = useState(0)
    const [listview, setlistview] = useState(false)
    const [auctionView, setAuctionView] = useState('grid')
    const [favLoad, setFavLoad] = useState(false)
    const [audio] = useState(
        global.storeConfigration?.bidding_sound?.value == 1 ? new Audio('/audio/ding.mp3') : '',
    )
    const params = new URLSearchParams(location.search)

    const active_sorts = {
        sorts: {
            sortcategoryTypes: ['2', '4', '5', '7', '8'],
            sortconditionTypes: ['2', '3', '5', '6'],
            sortsubCategoryTypes: ['1', '2', '3'],
            sortlocations: ['1', '2', '3', '18', '20'],
            sortall_notifications: [],
            sortbusiness_category: [],
            sortvat_registration: [],
        },
    }
    const getOrderBy = (orderby) => {
        let order = ' order by '

        if (orderby == '') {
            order += 'p.date_closed asc'
        }
        if (orderby == 3) {
            order += 'IF (p.auction = 1, p.wprice,p.bprice) asc'
        }
        if (orderby == 11) {
            order += 'IF (p.auction = 1, p.wprice,p.bprice) desc'
        }
        if (orderby == 6) {
            order += 'p.date_closed asc'
        }
        if (orderby == 2) {
            order += 'p.id desc'
        }
        if (orderby == 14) {
            if (global?.storeConfigration?.lot_sequence_order?.value == 1)
                order += 'CAST(p.lot_number AS UNSIGNED) ASC'
            else order += 'CAST(p.sku AS UNSIGNED) ASC'
        }
        if (orderby == 15) {
            if (global?.storeConfigration?.lot_sequence_order?.value == 1)
                order += 'CAST(p.lot_number AS UNSIGNED) DESC'
            else order += 'CAST(p.sku AS UNSIGNED) DESC'
        }
        if (
            global?.storeConfigration?.lot_sequence_order?.value == 1 &&
            orderby != 14 &&
            orderby != 15
        ) {
            order += ',CAST(p.lot_number AS UNSIGNED) ASC '
        }

        return order
    }

    const getSearchBar = (searchbar) => {
        let where = ''
        if (searchbar) {
            if (
                global.storeConfigration?.show_warehouse_location?.value == 1 &&
                global.storeConfigration?.lot_sequence_order?.value == 1
            ) {
                where +=
                    ' and (p.title like "%' +
                    addSlashes(searchbar) +
                    '%" or p.tags like "%' +
                    addSlashes(searchbar) +
                    '%" or p.description like "%' +
                    addSlashes(searchbar) +
                    '%" or p.category_id like "%' +
                    addSlashes(searchbar) +
                    '%" or p.location like "%' +
                    addSlashes(searchbar) +
                    '%" or p.lot_number like "%' +
                    addSlashes(searchbar) +
                    '%" or p.auctionid = "' +
                    addSlashes(searchbar) +
                    '")'
            } else if (global.storeConfigration?.lot_sequence_order?.value == 1) {
                where +=
                    ' and (p.title like "%' +
                    addSlashes(searchbar) +
                    '%" or p.tags like "%' +
                    addSlashes(searchbar) +
                    '%" or p.description like "%' +
                    addSlashes(searchbar) +
                    '%" or p.category_id like "%' +
                    addSlashes(searchbar) +
                    '%" or p.lot_number like "%' +
                    addSlashes(searchbar) +
                    '%" or p.auctionid = "' +
                    addSlashes(searchbar) +
                    '")'
            } else if (global.storeConfigration?.show_warehouse_location?.value == 1) {
                where +=
                    ' and (p.title like "%' +
                    addSlashes(searchbar) +
                    '%" or p.tags like "%' +
                    addSlashes(searchbar) +
                    '%" or p.description like "%' +
                    addSlashes(searchbar) +
                    '%" or p.category_id like "%' +
                    addSlashes(searchbar) +
                    '%" or p.location like "%' +
                    addSlashes(searchbar) +
                    '%" or p.auctionid = "' +
                    addSlashes(searchbar) +
                    '")'
            } else {
                where +=
                    ' and (p.title like "%' +
                    addSlashes(searchbar) +
                    '%" or p.tags like "%' +
                    addSlashes(searchbar) +
                    '%" or p.description like "%' +
                    addSlashes(searchbar) +
                    '%" or p.category_id like "%' +
                    addSlashes(searchbar) +
                    '%" or p.auctionid = "' +
                    addSlashes(searchbar) +
                    '")'
            }
        }
        return where
    }
    // const getCatgeory = () => {
    //     let where = `and p.catgegory_id=${}`

    // }
    const getWhere = (type, keys) => {
        let where = 'p.auction=1 and p.buynow=0 and p.market_status="open"'
        if (type === 'search') {
            where += getSearchBar(keys)
        }
        return where
    }
    const formik = useFormik({
        initialValues: {
            orderby: 6,
            category: [],
            subcategory: [],
            closed_auction_list: 0,
            perlimit: 50,
            page: 1,
            joinsdata: `p.auctionnotes,p.avatar as avatar_vc,p.content_head1,p.store_id,p.retailer_link,p.conditionunique,p.hasshipping,p.user_id,p.category_id${
                user?.id ? ',IFNULL(w.id,0) as watchlisted' : ''
            }`,
            nobids: 1,
            latestbid: 1,
            latestbid_user: 1,
            userid: user?.id,
            getbidding: 1,
            order_by: getOrderBy(6),
            customwhere: getWhere(),
            joins: user?.id
                ? `left join watchlists as w on w.project_id=p.id and w.user_id = ${user?.id} and w.is_delete = 0`
                : undefined,
        },
    })

    if (global?.storeConfigration?.need_buynow_search?.value == 1) {
        var filterValues = [
            {
                title: 'View',
                type: 'radio',
                noLabel: true,
                name: 'closed_auction_list',
                int: 1,
                class: 'col-12',
                item:
                    global?.storeConfigration?.remove_recently_closed?.value != 1
                        ? [
                              { id: 0, description: 'Live Items' },
                              { id: 1, description: 'Closed Items' },
                          ]
                        : [{ id: 0, description: 'Live Items' }],
                formik: formik,
            },
            {
                title: 'Type',
                type: 'radio',
                noLabel: true,
                name: 'from_id',
                int: 1,
                class: 'col-12',
                item: [
                    { id: 0, description: 'All' },
                    { id: 1, description: 'Auction' },
                    { id: 2, description: 'Buy Now' },
                ],
                formik: formik,
            },
            {
                title: 'Category',
                type: 'check',
                name: 'category',
                options: global.searchCategories.filter((main) => {
                    return true
                }),
                formik: formik,
            },
            {
                title: 'Sub category',
                type: 'check',
                name: 'subcategory',
                options: global.searchSubCategories.filter((subcat) => {
                    // if (
                    //     formik &&
                    //     formik.values &&
                    //     formik.values.category &&
                    //     formik.values.category.length
                    //         ? formik.values.category.includes(subcat.level_id.toString())
                    //         : false
                    // ) {
                    return true
                    //}
                }),
                formik: formik,
                hide: global.searchSubCategories.length == 0,
            },
        ]
    } else {
        var filterValues = [
            {
                title: 'View',
                type: 'radio',
                noLabel: true,
                name: 'closed_auction_list',
                int: 1,
                class: 'col-12',
                item:
                    global?.storeConfigration?.remove_recently_closed?.value != 1
                        ? [
                              { id: 0, description: 'Live Items' },
                              { id: 1, description: 'Closed Items' },
                          ]
                        : [{ id: 0, description: 'Live Items' }],
                formik: formik,
            },
            {
                title: 'Category',
                type: 'check',
                name: 'category',
                options: global.searchCategories.filter((main) => {
                    return true
                }),
                formik: formik,
            },
            {
                title: 'Sub category',
                type: 'check',
                name: 'subcategory',
                options: global.searchSubCategories.filter((subcat) => {
                    // if (
                    //     formik &&
                    //     formik.values &&
                    //     formik.values.category &&
                    //     formik.values.category.length
                    //         ? formik.values.category.includes(subcat.level_id.toString())
                    //         : false
                    // ) {
                    return true
                    //}
                }),
                formik: formik,
                hide: global.searchSubCategories.length == 0,
            },
        ]
    }

    if (global?.storeConfigration?.show_warehouse_location?.value == 1) {
        filterValues.push({
            title: 'Location',
            type: 'check',
            class: 'col-12',
            name: 'location',
            options: locationfillter,
            formik: formik,
        })
    }

    useEffect(() => {
        // setLoading(true)
        if (search_allproducts.from === 'searchPage') {
            setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
            setTotalViewProduct(search_allproducts.total_pagecnt)
            if (global?.storeConfigration?.show_warehouse_location?.value == 1) {
                var loc = []
                search_allproducts.locate.map((data) => {
                    loc.push({
                        description: data.name,
                        name: data.name,
                        value: data.name,
                        id: data.name,
                        active: 1,
                    })
                })
                setLocation(loc)
            }
        }
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [search_allproducts])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }

    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socket.on('cancelbidemitted', (data) => {
            handler(data, 'cancelbidemitted')
        })

        socketAuction.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socket.off('cancelbidemitted', (data) => {
                handler(data, 'cancelbidemitted')
            })

            socketAuction.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])

    const onHandlePage = (event, value) => {
        setPage(value)
        setLoading(true)
        // getAllSearchProducts({ ...formik.values, page: value }, 'searchPage')
        getAllSearchItems({ ...formik.values, page: value }, 'searchPage')
        setLoading(false)
        window.scrollTo(0, 0)
    }

    const getAllProduct = async (bool) => {
        // setLoading(true)
        // getAllSearchProducts(formik.values, 'searchPage').then((res) => {
        //     if (res.success === 'yes' && params.get('product'))
        //         getViewProduct({ product_id: Number(params.get('product')) })
        // })
        // setTimeout(() => {
        //     setLoading(false)
        // }, 1000)
    }
    useEffect(() => {
        // setLoading(true)
        /*  if (formik.values.closed_auction_list) {
            formik.values.orderby = '7'
        } */
        if (global?.storeConfigration?.need_buynow_search?.value == 1) {
            if (parseInt(formik.values.from_id) == 0) {
                formik.values.from = ''
            } else if (parseInt(formik.values.from_id) == 1) {
                formik.values.from = 'auction_only'
            } else {
                formik.values.from = 'buynow'
            }

            params.set('at', formik.values.from_id)

            history.replace({
                pathname: location.pathname,
                search: params.toString(),
            })
        }

        // getAllSearchProducts({ ...formik.values, page: 1 }, 'searchPage')
        getAllSearchItems({ ...formik.values, page: 1 }, 'searchPage')
        // setLoading(false)
    }, [formik.values, isAuthenticated])

    useEffect(() => {
        if (
            formik.values.category ||
            formik.values.subcategory ||
            formik.values.closed_auction_list ||
            formik.values.perlimit
        ) {
            setPage(1)
            formik.setFieldValue('page', 1)
        }
    }, [
        formik.values.category,
        formik.values.subcategory,
        formik.values.closed_auction_list,
        formik.values.perlimit,
        formik.values.order_by,
    ])
    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        formik.setFieldValue(
            'customwhere',
            params.get('q') ? getWhere('search', params.get('q')) : getWhere('search', ''),
        )
        formik.setFieldValue(
            'from_id',
            params.get('q') && params.get('at')
                ? params.get('at')
                : params.get('q')
                ? 0
                : params.get('at')
                ? params.get('at')
                : 1,
        )
    }, [window.location.search])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }
    const pageOptions = [
        {
            value: '50',
            show: 'Results 50 per page',
        },
        {
            value: '75',
            show: 'Results 75 per page',
        },
        {
            value: '100',
            show: 'Results 100 per page',
        },
    ]

    const sortShow = [
        {
            value: '',
            show: 'None',
        },
        {
            value: 3,
            show: 'Current price low to high',
        },
        {
            value: 11,
            show: 'Current price high to low',
        },
        {
            value: 6,
            show: 'Time remaining',
        },
        {
            value: 2,
            show: 'Recently listed',
        },
        {
            value: 14,
            show: 'Lot # low to high',
        },
        {
            value: 15,
            show: 'Lot # high to low',
        },
    ]

    return (
        <Layout props={props}>
            <UserActivity page="lot_search" />
            <div className="container mt-5 position-relative">
                <div
                    className="searchMiscAct d-flex justify-content-end flex-wrap my-3"
                    style={{ gap: '15px' }}
                >
                    {search_allproducts &&
                        !!totalViewProduct &&
                        totalViewProduct > formik.values.perlimit &&
                        !isLoading && (
                            <Pagination
                                count={Math.ceil(totalViewProduct / formik.values.perlimit)}
                                page={page}
                                onChange={onHandlePage}
                                className={classes2.root}
                            />
                        )}
                    <CustomSelect
                        label="Results per page"
                        name="resultsPerPage"
                        selectType="noBorder"
                        value={formik.values.perlimit}
                        size="small"
                        onChange={(event, editor) => {
                            formik.setFieldValue('perlimit', parseInt(event.target.value))
                        }}
                    >
                        {pageOptions.map((opt, optindex) => (
                            <option value={opt.value}>{opt.show}</option>
                        ))}
                    </CustomSelect>
                    <CustomSelect
                        label="Sort by"
                        value={formik.values.orderby}
                        size="small"
                        selectType="noBorder"
                        name="order_by"
                        // shrink={search.orderby !== '' ? true : false}
                        onChange={(event, editor) => {
                            formik.setFieldValue('order_by', getOrderBy(event.target.value))
                            formik.setFieldValue('orderby', event.target.value)
                        }}
                    >
                        {sortShow.map((opt, optindex) => (
                            <option value={opt.value}>{opt.show}</option>
                        ))}
                    </CustomSelect>
                    <Button
                        className="toggleRespDrawer"
                        variant="outlined"
                        onClick={toggleDrawer('bottom', true)}
                    >
                        <span className="material-icons">tune</span>
                        Filters
                    </Button>
                </div>
                <div className="searchContainer">
                    <div className="searchLt">
                        <FilterPanel formik={formik} filterValues={filterValues} />
                    </div>
                    <div className="searchRt">
                        {isLoading ? (
                            <div>
                                <Loaders name="product_grid_view" isLoading={isLoading} loop={6} />
                            </div>
                        ) : viewProduct.length > 0 ? (
                            <SearchProductList
                                listview={listview}
                                searchResult={viewProduct}
                                className="liquidationBuyCard"
                                getAllProducts={getAllProduct}
                                cardType={auctionView}
                                cardTheme="liquidation"
                                audio={audio}
                                enableZoomOnHover={true}
                                at={formik.values.from_id}
                            />
                        ) : (
                            <NoRecordsFound />
                        )}
                        {search_allproducts &&
                            !!totalViewProduct &&
                            totalViewProduct > formik.values.perlimit &&
                            !isLoading && (
                                <Pagination
                                    count={Math.ceil(totalViewProduct / formik.values.perlimit)}
                                    page={page}
                                    onChange={onHandlePage}
                                />
                            )}
                    </div>
                </div>
            </div>
            <Drawer
                anchor="bottom"
                open={state['bottom']}
                className="responsiveFilterDrawer"
                onClose={toggleDrawer('bottom', false)}
                onOpen={toggleDrawer('bottom', true)}
            >
                <div
                    className={clsx(classes.list, 'bottom')}
                    role="presentation"
                    // onClick={toggleDrawer('bottom', false)}
                    onKeyDown={toggleDrawer('bottom', false)}
                >
                    <FilterPanel formik={formik} filterValues={filterValues} />
                </div>
            </Drawer>
        </Layout>
    )
}

export default AuctionSearchComponent

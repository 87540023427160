import React, { useState, useContext, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import './MultisellerListCard.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import FavouriteCheckbox from '../../Product/components/atoms/FavoriteCheckbox'
import Timer from '../../Product/common/timer'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import {
    dateFormatFront,
    handleRedirectInternal,
    removeHTMLTags,
} from '../../Product/common/components'
import { currencyFormat, sellerNameFormat } from '../../Product/common/components'
import { Button, Tooltip, Popover } from '@material-ui/core'
import Bidding from '../../Product/components/molecules/Bidding/BiddingItem'
import Biddingforwardandreverse from '../../Product/components/molecules/Bidding/biddingforwardandreverse'
import moment from 'moment-timezone'
import AddToCalendar from '../../Product/components/molecules/AddToCalendar'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import AuthContext from '../../Product/context/auth/authContext'
import CurrencyConverterFormat from '../../utils/CommonFunctionality/CurrencyConverter/CurrencyConverterFormat'
import CartContext from '../../Product/context/cart/cartContext'
import Facebook from '../../utils/CommonFunctionality/Share/Facebook'
import Whatsapp from '../../utils/CommonFunctionality/Share/Whatsapp'
import Twitter from '../../utils/CommonFunctionality/Share/Twitter'
import Email from '../../utils/CommonFunctionality/Share/Email'
import Pinterest from '../../utils/CommonFunctionality/Share/Pinterest'
import Copy from '../../utils/CommonFunctionality/Share/Copy'
import ReadMore from '../ReadMore'

const MultisellerListCard = ({
    data,
    details,
    isFrame,
    lotData,
    type,
    handleClick,
    bidBtnLabel,
    theme,
    bidStatus,
    timerTheme,
    // lotCount,
    auction_and_buynow,
    className,
    cardTheme,
    standalonePage,
    card_theme,
    auctionTimer,
    viewProduct,
    setViewProduct,
    lotDetails,
    is_registered,
    setAlert,
    private_auction,
    openCategory,
}) => {
    const addDefaultSrc = (e) => {
        e.target.src = `${global?.storeDetails?.logoValue}`
        e.target.className = `gridImage default`
    }
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const { isAuthenticated, user } = authContext
    const cartContext = useContext(CartContext)
    const [selectedProduct, setSelectedProduct] = useState({})
    const { addToCart, getUserCart, removeFromCart, responseStatus, buyer_cartitems } = cartContext

    const [active, setActive] = useState(false)
    const [bidText, setBidText] = useState('Bid Now')
    const getBidText = (data) => {
        if (
            isAuthenticated &&
            (data?.latestbid_user != null || data?.highest_bidder_user_id != null) &&
            user?.id != undefined
        ) {
            if (data?.auction) {
                if (
                    (data?.latestbid_user == user?.id ||
                        data?.highest_bidder_user_id == user?.id) &&
                    data?.wprice >= data?.rprice
                ) {
                    if (data?.market_status == 'open') {
                        setBidText('Winning')
                    } else if (data?.market_status == 'sold') {
                        setBidText('Won')
                    }
                } else if (
                    data?.latestbid_user != user?.id &&
                    (data?.currentUserBidAmount || data?.bid_or_not || data?.cbidnot)
                ) {
                    if (data?.market_status == 'open') {
                        setBidText('Outbid')
                    } else if (data?.market_status == 'sold') {
                        setBidText('Lost')
                    }
                } else {
                    setBidText('Bid Now')
                }
            }
        } else {
            setBidText('Bid Now')
        }
    }
    useEffect(() => {
        getBidText(lotData)
    }, [lotData])
    useEffect(() => {
        if (buyer_cartitems.results) {
            if (lotData?.id) {
                let position = _.findIndex(buyer_cartitems.results, {
                    project_id: lotData?.id,
                })
                position === -1 ? setActive(false) : setActive(true)
            }
        }
    }, [buyer_cartitems.results, lotData?.id])

    // useEffect(() => {
    //     if (responseStatus) {
    //         if (responseStatus.from === 'addToCart') {
    //             if (responseStatus.status === 'success') {
    //                 getUserCart()
    //             }
    //         } else if (responseStatus.from === 'removeFromCart') {
    //             if (responseStatus.status === 'success') {
    //                 getUserCart()
    //             }
    //         }
    //     }
    // }, [responseStatus])
    const isHyperMicroTheme = document.querySelector('body').classList.contains('hyperMicro')
    const [shareAnchorEl, setShareAnchorEl] = useState(null)
    const handleShareClick = (event) => {
        setShareAnchorEl(event.currentTarget)
    }

    const handleShareClose = () => {
        setShareAnchorEl(null)
    }

    const openshare = Boolean(shareAnchorEl)
    const id = open ? 'simple-popover' : undefined

    const handleCart = async (id) => {
        if (active) {
            removeFromCart({ id })
        } else addToCart({ id: lotData.id, qty: lotData.qty })
    }

    const hanlderedirection = (id) => {
        if (parseInt(is_registered) == 0 && parseInt(private_auction) == 1) {
            setAlert('Please register auction and continue!', 'error')
            window.scrollTo(0, 0)
        } else {
            history.push({
                pathname: `/productView/${id}`,
                state: { lotid: lotDetails },
            })
        }
    }

    const checkThemeClassName = document.body.className == 'liquidationTwo primeBids'

    return type === 'lot' ? (
        <>
            {lotData ? (
                <div
                    className={`multisellerListCard lotCard ${className}${
                        bidText === 'Winning' && 'winningcard'
                    } 
                ${bidText === 'Outbid' && 'losingcard'}`}
                >
                    <div style={{ width: '100%' }}>
                        <div className="gridDiv">
                            {card_theme == 'liquidationTwo' ? (
                                <div className="lctnTxtWrpr d-flex align-items-center justify-content-end">
                                    <span className="material-icons">location_on</span>
                                    <span className="location-class">{lotData?.auction_city}</span>
                                </div>
                            ) : null}
                            {lotData.market_status == 'open' ? (
                                <FavouriteCheckbox
                                    watchlisted={
                                        lotData.watchlistid || lotData.watchlisted || lotData.isFav
                                    }
                                    project_id={lotData.id}
                                    from={'card'}
                                    viewProduct={viewProduct}
                                    setViewProduct={setViewProduct}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="grid-img-wrap">
                            {global?.storeConfigration?.sold_status?.value == '1' ? (
                                <></>
                            ) : (
                                <>
                                    {lotData.market_status === 'sold' && (
                                        <div className="soldWrapper">
                                            <p>SOLD</p>
                                        </div>
                                    )}
                                    {lotData.market_status === 'closed' && (
                                        <div className="soldWrapper">
                                            <p>UNSOLD</p>
                                        </div>
                                    )}
                                </>
                            )}
                            <LazyLoadImage
                                effect="blur"
                                placeholderSrc="/assets/svg/imageLoading.svg"
                                height="100%"
                                width="100%"
                                src={`${
                                    lotData.content_head1 === '0' || lotData.store_id === 0
                                        ? process.env.REACT_APP_BASE_URL + 'uploads/product/'
                                        : process.env.REACT_APP_PRODUCT_IMAGE_URL
                                }${
                                    global.storeConfigration?.image_compression?.value == 1
                                        ? 'thumb_'
                                        : ''
                                }${
                                    (lotData?.lotImages?.length &&
                                        lotData?.lotImages[0]?.file_name) ||
                                    lotData.file_name ||
                                    lotData.avatar_vc
                                }`}
                                // onClick={() =>
                                //     standalonePage
                                //         ? handleRedirectInternal(history, `productView/${lotData.id}`)
                                //         : handleClick(lotData.id)
                                // }
                                onClick={() =>
                                    global.storeDetails?.theme === 9
                                        ? hanlderedirection(lotData.id)
                                        : standalonePage
                                        ? handleRedirectInternal(
                                              history,
                                              `productView/${lotData.id}`,
                                          )
                                        : handleClick(lotData.id)
                                }
                                className="gridImage"
                                onError={addDefaultSrc}
                            />

                            <div className="listVIewShare">
                                {cardTheme === 'liquidationTwo' && (
                                    <>
                                        {isHyperMicroTheme && (
                                            <>
                                                <span
                                                    aria-describedby={id}
                                                    className="material-icons ml-2 share-Icon"
                                                    onClick={handleShareClick}
                                                >
                                                    share
                                                </span>
                                                <Popover
                                                    id={id}
                                                    open={openshare}
                                                    anchorEl={shareAnchorEl}
                                                    onClose={handleShareClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    }}
                                                >
                                                    {console.log(
                                                        selectedProduct,
                                                        'selectedProduct',
                                                    )}
                                                    <div className="pvMiscActions mb-0 p-2">
                                                        <Facebook
                                                            path={`/product/${selectedProduct.id}`}
                                                            title={selectedProduct.title}
                                                        />
                                                        <Whatsapp
                                                            path={`/product/${selectedProduct.id}`}
                                                            title={selectedProduct.title}
                                                        />
                                                        {global.storeConfigration
                                                            ?.disable_twitter_sharing?.value !=
                                                        1 ? (
                                                            <Twitter
                                                                path={`/product/${selectedProduct.id}`}
                                                                title={selectedProduct.title}
                                                            />
                                                        ) : null}
                                                        <Email
                                                            path={`/product/${selectedProduct.id}`}
                                                            subject={selectedProduct.title}
                                                            body={selectedProduct.description}
                                                        />
                                                        {global.storeConfigration
                                                            ?.disable_pinterest_sharing?.value !=
                                                        1 ? (
                                                            <Pinterest
                                                                path={`/product/${selectedProduct.id}`}
                                                                title={selectedProduct.title}
                                                                media={
                                                                    `${
                                                                        selectedProduct.content_head1 ===
                                                                        '0'
                                                                            ? process.env
                                                                                  .REACT_APP_BASE_URL +
                                                                              'uploads/product/'
                                                                            : process.env
                                                                                  .REACT_APP_PRODUCT_IMAGE_URL
                                                                    }` + selectedProduct.avatar
                                                                }
                                                            />
                                                        ) : null}
                                                        <div
                                                            className="cpyToClipDiv"
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <Copy
                                                                text={`/productView/${selectedProduct.id}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </Popover>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            {(cardTheme === 'multiseller' || cardTheme === 'liquidationTwo') &&
                            bidStatus === true &&
                            lotData.auction != 0 ? (
                                <>
                                    {bidText == 'Winning' ? (
                                        <p className="wngSts grnTxt">You Are Winning!</p>
                                    ) : bidText == 'Won' ? (
                                        <p className="wngSts grnTxt">You Won!</p>
                                    ) : bidText == 'Outbid' ? (
                                        <p className="wngSts LsgTxt">You Are Losing</p>
                                    ) : bidText == 'Lost' ? (
                                        <p className="wngSts LsgTxt">You Lost</p>
                                    ) : (
                                        <p className="wngSts"></p>
                                    )}
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <div className="mslDesc">
                        {card_theme === 'FoodIndustry' ? (
                            <h5 className="hostedBy">
                                {lotData.product_city ? lotData.product_city : ''}
                            </h5>
                        ) : lotData?.seller_name &&
                          global.storeConfigration?.disable_hosted_by?.value != 1 ? (
                            <h6 className="hostedBy">
                                Hosted By: {sellerNameFormat(lotData.seller_name)}
                            </h6>
                        ) : null}
                        <h3
                            className="listTitle"
                            dangerouslySetInnerHTML={{
                                __html: lotData.title,
                            }}
                            onClick={() =>
                                standalonePage
                                    ? handleRedirectInternal(history, `productView/${lotData.id}`)
                                    : handleClick(lotData.id)
                            }
                        />
                        {parseInt(lotData.lowest) > 0 || parseInt(lotData.highest) > 0 ? (
                            <p className="estimates">
                                <span>Est:</span>
                                {`${
                                    ' ' +
                                    currencyFormat(lotData.lowest) +
                                    ' - ' +
                                    currencyFormat(lotData.highest)
                                }`}
                            </p>
                        ) : (
                            ''
                        )}
                        {card_theme === 'liquidationTwo' &&
                        (global.storeConfigration?.condition_field?.value == 1 ||
                            global.storeConfigration?.damaged_field?.value == 1 ||
                            global.storeConfigration?.functional_field?.value == 1 ||
                            global.storeConfigration?.missing_parts_field?.value == 1) ? (
                            <div className="pdtStsInfo mb-2">
                                <ul>
                                    <li>
                                        <label>Condition:</label>
                                        <Tooltip
                                            title={lotData.condition_field || '-'}
                                            arrow
                                            placement="top"
                                        >
                                            <p
                                                className={`m-0 cursorDecoy ${
                                                    lotData.condition_field?.includes(
                                                        'Used - Like New',
                                                    )
                                                        ? 'text-Yellow'
                                                        : lotData.condition_field?.includes('New')
                                                        ? 'text-success'
                                                        : 'text-danger'
                                                }`}
                                            >
                                                {lotData.condition_field || '-'}
                                            </p>
                                        </Tooltip>
                                    </li>

                                    <li>
                                        <label>Functional:</label>
                                        <p
                                            className={`m-0 ${
                                                lotData.functional_field?.includes('Yes')
                                                    ? 'text-success'
                                                    : 'text-danger'
                                            }`}
                                        >
                                            {lotData.functional_field || '-'}
                                        </p>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <label>Damaged:</label>
                                        <p
                                            className={`m-0 ${
                                                lotData.damaged_field?.includes('No')
                                                    ? 'text-success'
                                                    : 'text-danger'
                                            }`}
                                        >
                                            {lotData.damaged_field || '-'}
                                        </p>
                                    </li>

                                    <li>
                                        <label>Missing Parts:</label>
                                        <p
                                            className={`m-0 ${
                                                lotData.missing_parts_field?.includes('Yes')
                                                    ? 'text-danger'
                                                    : 'text-success'
                                            }`}
                                        >
                                            {lotData.missing_parts_field || '-'}
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        ) : (
                            ''
                        )}
                        {global.storeConfigration?.condition_with_color?.value == 1 ? (
                            <h6 className="galleryPrice condition">
                                <span>Condition: </span>
                                <span
                                    className={`prcTxtVal ${
                                        lotData.auctionnotes?.trim()?.toLowerCase()?.includes('new')
                                            ? 'badge badge-success text-white'
                                            : lotData.auctionnotes
                                                  ?.trim()
                                                  ?.toLowerCase()
                                                  ?.includes('open box')
                                            ? 'badge badge-primary text-white'
                                            : lotData.auctionnotes
                                                  ?.trim()
                                                  ?.toLowerCase()
                                                  ?.includes('damage')
                                            ? 'badge badge-danger text-white'
                                            : ''
                                    }`}
                                    title={lotData.auctionnotes ? lotData.auctionnotes : '-'}
                                >
                                    {lotData.auctionnotes ? lotData.auctionnotes : '-'}
                                </span>
                            </h6>
                        ) : null}

                        {cardTheme == 'liveStock' ? (
                            <>
                                <div className="lsListInfo">
                                    <h6>
                                        Gender <span>{lotData.content_head3}</span>
                                    </h6>
                                    <h6>
                                        Age <span>{lotData.content_head2}</span>
                                    </h6>
                                    <h6>
                                        Sire <span>{lotData.custom_field_1}</span>
                                    </h6>
                                    <h6>
                                        Dam <span>{lotData.custom_field_2}</span>
                                    </h6>
                                    {/* <h6>
                                        Gait <span>{lotData.content_head2}</span>
                                    </h6> */}
                                    <h6>
                                        Location <span>{lotData.productLocation}</span>
                                    </h6>
                                    {lotData.category_id ? (
                                        <h6>
                                            Type <span>{lotData.category_id}</span>
                                        </h6>
                                    ) : null}
                                </div>
                                <div className="lsListMisc">
                                    <h6>
                                        Earnings <span>$ {lotData.modelnumber}</span>
                                    </h6>
                                </div>
                            </>
                        ) : (
                            <>
                                {/* <p
                                    className="listDesc"
                                    dangerouslySetInnerHTML={{
                                        __html: lotData.description,
                                    }}
                                /> */}
                                {/* <p className="listDescLabel">Description:</p>
                                <p className="listDesc">{removeHTMLTags(lotData.description)}</p> */}
                            </>
                        )}
                    </div>
                    <div className="mslMisc">
                        {lotData?.auction == 1 || auction_and_buynow == 1 ? (
                            <>
                                {lotData.market_status === 'open' ? (
                                    <Timer
                                        date_added={lotData.date_added}
                                        date_closed={lotData.date_closed}
                                        withText={1}
                                        endText={'Time left' + ':'}
                                        startText={'Starts in' + ':'}
                                        theme={timerTheme == '' ? '' : 'multiseller'}
                                        product_id={lotData.id}
                                        viewProduct={viewProduct}
                                        setViewProduct={setViewProduct}
                                    />
                                ) : (
                                    <span className="closedText">Auction Closed</span>
                                    // <div className="timerWrapper">
                                    //         <span className="material-icons-outlined">timer</span>
                                    //     <h6 className="timeLabel">Auction closed</h6>
                                    // </div>
                                )}
                                <hr />
                            </>
                        ) : (
                            ''
                        )}

                        {cardTheme === 'liquidationTwo' ? (
                            <>
                                {lotData.retailer_link && lotData.mprice ? (
                                    <h6 className="galleryPrice rtlrPrice">
                                        <span className="prcLabel">
                                            Retail Price:{' '}
                                            <a
                                                href={lotData.retailer_link}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                (Retailer)
                                            </a>
                                        </span>
                                        &nbsp;
                                        <span className="prcTxtVal">
                                            {currencyFormat(
                                                parseFloat(lotData.mprice || 0).toFixed(2),
                                            )}
                                            <CurrencyConverterFormat amount={lotData.mprice} />
                                        </span>
                                    </h6>
                                ) : (
                                    <h6 className="galleryPrice rtlrPrice"></h6>
                                )}
                            </>
                        ) : (
                            <></>
                        )}

                        {lotData.buynow && lotData.bprice && lotData.auction !== 1 ? (
                            <h6>
                                {lotData.market_status === 'sold' ? 'Sold' : 'Buy Now'} Price:{' '}
                                <span>{currencyFormat(parseFloat(lotData.bprice).toFixed(2))}</span>
                            </h6>
                        ) : (
                            <div className="lotBidInfo">
                                {lotData.market_status == 'closed' ? (
                                    <h6 className="msUnsold">
                                        <span>Unsold</span>
                                    </h6>
                                ) : global.pluginConfiguration?.reverse_auction?.enable &&
                                  parseInt(lotData.content_head5) == 1 ? (
                                    <>
                                        <h6>
                                            {lotData.market_status == 'sold'
                                                ? 'Sold Bid'
                                                : 'Start Bid'}
                                            <span>
                                                {currencyFormat(
                                                    parseFloat(lotData.wprice).toFixed(2),
                                                )}
                                            </span>
                                        </h6>

                                        <h6>{lotData.market_status == 'sold' ? 1 : 0} Bids</h6>
                                    </>
                                ) : (
                                    <>
                                        {lotData.latestbid ? (
                                            <h6>
                                                {lotData.market_status == 'sold'
                                                    ? 'Sold Bid'
                                                    : 'Current Bid'}
                                                <span>
                                                    {lotData.latestbid
                                                        ? currencyFormat(
                                                              parseFloat(lotData.wprice).toFixed(2),
                                                          )
                                                        : 'N/A'}
                                                </span>
                                            </h6>
                                        ) : (
                                            <h6>
                                                Start Bid
                                                <span>
                                                    {currencyFormat(
                                                        parseFloat(lotData.sprice).toFixed(2),
                                                    )}
                                                </span>
                                            </h6>
                                        )}
                                        <h6>{lotData.nobids} Bids</h6>
                                    </>
                                )}
                            </div>
                        )}
                        {lotData.buynow && lotData.bprice && lotData.auction !== 1 ? (
                            <>
                                {cardTheme === 'liquidationTwo' ? (
                                    <>
                                        <PrimaryButton
                                            name={lotData.id}
                                            onClick={() => {
                                                if (
                                                    lotData.market_status != 'sold' &&
                                                    isAuthenticated
                                                ) {
                                                    handleCart(lotData.id)
                                                } else if (
                                                    lotData.market_status != 'sold' &&
                                                    !isAuthenticated
                                                ) {
                                                    handleRedirectInternal(history, 'login')
                                                }
                                            }}
                                            label={
                                                isAuthenticated
                                                    ? lotData.market_status == 'sold'
                                                        ? 'Lot Sold'
                                                        : active === true
                                                        ? 'Added to Cart'
                                                        : `Buy it Now: ${currencyFormat(
                                                              lotData.bprice,
                                                          )}`
                                                    : 'Login To Buy'
                                            }
                                        />
                                    </>
                                ) : (
                                    <PrimaryButton
                                        name={lotData.id}
                                        onClick={() => {
                                            if (
                                                lotData.market_status != 'sold' &&
                                                isAuthenticated
                                            ) {
                                                handleClick(lotData.id)
                                            } else if (
                                                lotData.market_status != 'sold' &&
                                                !isAuthenticated
                                            ) {
                                                handleRedirectInternal(history, 'login')
                                            }
                                        }}
                                        label={
                                            isAuthenticated
                                                ? lotData.market_status == 'sold'
                                                    ? 'Lot Sold'
                                                    : 'Buy Now'
                                                : 'Login To Buy'
                                        }
                                    />
                                )}
                            </>
                        ) : lotData?.user_id == user?.id ? (
                            <p style={{ whiteSpace: 'nowrap' }}>
                                {lotData.buynow
                                    ? 'You cannot buy your own product!'
                                    : 'You cannot bid on your own product!'}
                            </p>
                        ) : (
                            <>
                                {lotData.market_status != 'open' ||
                                lotData.productClosed ||
                                (lotData.date_closed &&
                                    moment(lotData.date_closed).isValid() &&
                                    auctionTimer &&
                                    moment(auctionTimer).isValid() &&
                                    new Date(lotData.date_closed).getTime() -
                                        new Date(auctionTimer).getTime() <
                                        0) ? (
                                    <b>Auction Closed</b>
                                ) : (
                                    <div className="d-flex flex-wrap subLotLine">
                                        <div
                                            className={`row flex-nowrap slAct ${
                                                !isAuthenticated && 'notLogged'
                                            }`}
                                        >
                                            {lotData.auction ? (
                                                global.pluginConfiguration?.reverse_auction
                                                    ?.enable ? (
                                                    <Biddingforwardandreverse
                                                        theme="multiseller"
                                                        data={lotData}
                                                        hard="1"
                                                        shortText={bidBtnLabel}
                                                        is_registered={is_registered}
                                                        private_auction={private_auction}
                                                    />
                                                ) : (
                                                    <Bidding
                                                        theme="multiseller"
                                                        data={lotData}
                                                        hard={
                                                            global.storeConfigration?.hard_bid
                                                                ?.value || '1'
                                                        }
                                                        shortText={bidBtnLabel}
                                                    />
                                                )
                                            ) : null}
                                        </div>
                                    </div>
                                )}

                                {cardTheme == 'liquidationTwo' && lotData.auction ? (
                                    <div className="spByNw">
                                        {type == 'buynow' && type != 'Auction' ? (
                                            <div className="spByNw"></div>
                                        ) : (
                                            ((parseInt(lotData.buynow) == 1 &&
                                                parseInt(lotData.auction) == 0) ||
                                                (parseInt(lotData.wprice) <=
                                                    parseInt(lotData.bprice) &&
                                                    parseInt(lotData.buynow) == 1 &&
                                                    parseInt(lotData.auction) == 1)) &&
                                            lotData.market_status == 'open' && (
                                                <>
                                                    {isAuthenticated ? (
                                                        <PrimaryButton
                                                            btnSize={'small'}
                                                            onClick={() => handleCart(lotData.id)}
                                                            disabled={lotData.buynow == 0}
                                                            label={
                                                                active === true
                                                                    ? 'Added to Cart'
                                                                    : `Buy it Now: ${currencyFormat(
                                                                          lotData.bprice,
                                                                      )}`
                                                            }
                                                        />
                                                    ) : (
                                                        <PrimaryButton
                                                            btnSize={'small'}
                                                            onClick={() =>
                                                                handleRedirectInternal(
                                                                    history,
                                                                    'login',
                                                                )
                                                            }
                                                            disabled={lotData.buynow == 0}
                                                            label={`Buy it Now: ${currencyFormat(
                                                                lotData.bprice,
                                                            )}`}
                                                        />
                                                    )}
                                                </>
                                            )
                                        )}
                                    </div>
                                ) : (
                                    ''
                                )}

                                <Button
                                    className="viewMoreBtn"
                                    // onClick={() => handleClick(lotData.id)}

                                    onClick={() =>
                                        global.storeDetails?.theme === 9
                                            ? hanlderedirection(lotData.id)
                                            : standalonePage
                                            ? handleRedirectInternal(
                                                  history,
                                                  `productView/${lotData.id}`,
                                              )
                                            : handleClick(lotData.id)
                                    }
                                >
                                    View more details
                                    <span className="material-icons">arrow_right_alt</span>
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            ) : null}
        </>
    ) : (
        <>
            {data ? (
                <div className="multisellerListCard">
                    {data.file_name ? (
                        <div className="grid-img-wrap">
                            <Link to={details} target={isFrame ? '_blank' : ''}>
                                <LazyLoadImage
                                    effect="blur"
                                    placeholderSrc="/assets/svg/imageLoading.svg"
                                    height="100%"
                                    width="100%"
                                    src={`${
                                        data.store_id === 0
                                            ? process.env.REACT_APP_BASE_URL + 'uploads/product/'
                                            : process.env.REACT_APP_PRODUCT_IMAGE_URL
                                    }${
                                        global.storeConfigration?.image_compression?.value == 1
                                            ? 'thumb_'
                                            : ''
                                    }${data.file_name}`}
                                    className="gridImage"
                                    onError={addDefaultSrc}
                                />
                                {card_theme != 'lotsauction' && (
                                    <span className={`badge ${data.con_check ? 'live' : 'timed'}`}>
                                        {data.con_check ? 'Live' : 'Timed'}
                                    </span>
                                )}
                            </Link>
                            {/* {lotCount ? (
                                <span className="lotCount">
                                    <span className="material-icons">view_carousel</span>
                                    {lotCount} lot{lotCount > 1 && 's'}
                                </span>
                            ) : (
                                ''
                            )} */}
                        </div>
                    ) : (
                        <Skeleton height={220} width={720} />
                    )}

                    <div className="mslDesc">
                        {card_theme != ('liquidationTwo' && 'lotsauction') && (
                            <>
                                {data?.seller_name &&
                                global.storeConfigration?.disable_hosted_by?.value != 1 ? (
                                    <h6 className="hostedBy">
                                        Hosted By: {sellerNameFormat(data.seller_name)}
                                    </h6>
                                ) : null}
                            </>
                        )}
                        <Link to={details} target={isFrame ? '_blank' : ''}>
                            <h3
                                className="listTitle"
                                dangerouslySetInnerHTML={{
                                    __html: data.title,
                                }}
                            />
                        </Link>
                        {card_theme == 'lotsauction' && (
                            <>
                                <div className="lots-details mb-2">
                                    <div className="">
                                        {data.lotCount} item{data.lotCount > 1 && 's'}
                                    </div>

                                    <div className="pick-up">
                                        <img src="/lots-images/shipping-truck.svg" alt="" />
                                        Pick up only
                                    </div>
                                </div>
                            </>
                        )}
                        {card_theme === 'liquidationTwo' && (
                            <div>
                                <p>
                                    {data.auction_shipping == 1
                                        ? `Shipping ${
                                              data.auction_localpickup == 0 &&
                                              data.auction_shipping == 1
                                                  ? 'Only'
                                                  : ''
                                          }`
                                        : ''}
                                    {data.auction_shipping == 1 && data.auction_localpickup == 1
                                        ? ' and '
                                        : ''}
                                    {data.auction_localpickup == 1
                                        ? `Local Pickup ${
                                              data.auction_localpickup == 1 &&
                                              data.auction_shipping == 0
                                                  ? 'Only'
                                                  : ''
                                          } | ${data.auction_state}`
                                        : null}
                                </p>
                            </div>
                        )}

                        {theme == 'HeavyEquipment' && (
                            <>
                                <div className="badgeSpan">
                                    <span>Auction Id: #{data.id}</span>
                                    <AddToCalendar data={data} />
                                </div>
                                <div className="AuctionDateRange">
                                    <span>
                                        <b> Start Date:</b>{' '}
                                        {moment(data.date_added)
                                            .tz('America/New_York')
                                            .format('MMM Do YYYY, h:mm a')}
                                    </span>
                                    <span>
                                        <b>End Date:</b>{' '}
                                        {moment(data.date_closed)
                                            .tz('America/New_York')
                                            .format('MMM Do YYYY, h:mm a')}
                                    </span>
                                </div>
                            </>
                        )}
                        <label className="mb-1 alv-descp">Description:</label>
                        {checkThemeClassName ? (
                            <>
                                <ReadMore data={data.description} className="listDesc" limit={30} />
                            </>
                        ) : (
                            <>
                                <p
                                    className="listDesc"
                                    dangerouslySetInnerHTML={{ __html: data.description }}
                                />
                            </>
                        )}

                        <div className="lotImagesGrid">
                            {data.lot_images &&
                                data.lot_images.map((val, index) => (
                                    <>
                                        <LazyLoadImage
                                            effect="blur"
                                            placeholderSrc="/assets/svg/imageLoading.svg"
                                            height="100%"
                                            width="100%"
                                            src={`${
                                                data.store_id === 0
                                                    ? process.env.REACT_APP_BASE_URL +
                                                      'uploads/product/'
                                                    : process.env.REACT_APP_PRODUCT_IMAGE_URL
                                            }${
                                                global.storeConfigration?.image_compression
                                                    ?.value == 1
                                                    ? 'thumb_'
                                                    : ''
                                            }${val?.file_name}`}
                                            key={index}
                                            onError={addDefaultSrc}
                                        />
                                    </>
                                ))}
                        </div>
                    </div>
                    <div className="mslMisc">
                        {card_theme == 'lotsauction' && (
                            <>
                                {data.market_status === 'open' ? (
                                    <div className="timer-wrapper li-timer-wrap">
                                        <div className="li-timer">
                                            <Timer
                                                date_added={data.date_added}
                                                date_closed={data.date_closed}
                                                withText={1}
                                                endText={'Ends In'}
                                                startText="Starts in"
                                                theme=""
                                                icon={true}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    // <span className="closedText">Auction Closed</span>
                                    <div className="timerWrapper">
                                        <span className="material-icons-outlined">timer</span>
                                        <h6 className="timeLabel">Auction closed</h6>
                                    </div>
                                )}
                            </>
                        )}
                        {card_theme != ('liquidationTwo' && 'lotsauction') && (
                            <>
                                {data.market_status === 'open' ? (
                                    <>
                                        <Timer
                                            date_added={data.date_added}
                                            date_closed={data.date_closed}
                                            withText={1}
                                            endText={'Time left' + ':'}
                                            startText={'Starts in' + ':'}
                                            theme={theme ? '' : 'multiseller'}
                                            icon={true}
                                        />
                                    </>
                                ) : (
                                    // <span className="closedText">Auction Closed</span>
                                    <div className="timerWrapper">
                                        <span className="material-icons-outlined">timer</span>
                                        <h6 className="timeLabel">Auction closed</h6>
                                    </div>
                                )}
                            </>
                        )}
                        {/* <p>Lots : {data.lotCount}</p> */}
                        <PrimaryButton
                            onClick={() =>
                                handleRedirectInternal(
                                    history,
                                    parseInt(data?.event_enable ? data.event_enable : 0) == 1
                                        ? `eventAuction/${data.id}`
                                        : details,
                                )
                            }
                        >
                            {card_theme === 'liquidationTwo'
                                ? 'EXPLORE AUCTION'
                                : 'Browse Auction Lots'}
                        </PrimaryButton>
                        {card_theme === 'liquidationTwo' && (
                            <div className="strsEndsDat">
                                {global?.storeConfigration?.timer_string_change?.value ? (
                                    <p className="showEnds">
                                        Ends on {dateFormatFront(data.date_closed)}
                                    </p>
                                ) : (
                                    <p className="showEnds">
                                        Ends {dateFormatFront(data.date_closed)}
                                    </p>
                                )}
                            </div>
                        )}
                        {card_theme === 'lotsauction' && (
                            <div className="bids-buttons-wrap mt-4">
                                <Button
                                    className="w-100"
                                    onClick={(e) => {
                                        handleRedirectInternal(
                                            history,
                                            global.storeDetails?.theme == 19
                                                ? details.replace('cat=0', 'cat=1')
                                                : details + '?cat=1',
                                        )
                                    }}
                                >
                                    Categories
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            ) : null}
        </>
    )
}

export default MultisellerListCard

import React, { useState, useEffect, useContext, useRef } from 'react'
// import openSocket from 'socket.io-client'
import { useHistory } from 'react-router-dom'
import SubLots from './SubLots'
import ProductAuctionTop from './ProductAuctionTop'
import SlidePanel from '../../../Component/SlidePanel'
import './product.css'
import _ from 'lodash'

import { socket, socketAuction } from '../../../Product/common/socket'

import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import {
    make_an_offer_update_socket_multiple,
    messageHandler,
    reverse_auction_item,
} from '../../../Product/common/socketHandler'
import Loaders from '../../../Product/components/molecules/Loaders'
import Layout from '../Layout'
import UserActivity from '../../../utils/CommonFunctionality/UserActivity'
import { mapData } from '../../../Product/common/components'
import { useFormik } from 'formik'
import DateTime from '../../../Component/DateTime'
import AuctionTerms from '../../../Component/AuctionTerms'
import CategoryModal from '../../../Component/Categories/CategoryModal'
import FilterPanel from '../../../Product/components/organisms/FilterPanel'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Rating } from '@material-ui/lab'
import StarBorderIcon from '@material-ui/icons/StarBorder'

const ProductAuctionComponent = (props) => {
    const authContext = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const [private_auction, setPrivateauction] = useState(0)
    const [parentcategories, setParentCategories] = useState([])
    const [childcategories, setChildcategories] = useState([])
    const { user, isAuthenticated } = authContext
    const { setAlert } = alertContext
    const [audio] = useState(
        global.storeConfigration?.bidding_sound?.value == 1 ? new Audio('/audio/ding.mp3') : '',
    )
    const {
        search_allauctions,
        search_allauctionproducts,
        edit_auction,
        getLotList,
        getEditAuction,
        auc_cat_value,
        getauctioncat_subcat,
    } = productContext

    let history = useHistory()
    const [auctionData, setAuctionData] = useState({})
    const [minMaxDate, setMinMaxDate] = useState({})
    const [subLotData, setSubLotData] = useState([])
    const [upcomming, setUpcomming] = useState(false)
    const [subLotDataCount, setSubLotDataCount] = useState(0)
    let auctionid = props.match.params.id
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [subLotSearch, setSubLotSearch] = useState({
        page: 1,
        //sortBy: 7,
        new_order_io: 5,
        lotof: auctionid,
        limit: 50,
        market_status: 'open',
        auction_io: 1,
        lot_sequence_order:
            global?.storeConfigration?.lot_sequence_order?.value == 1 ? 1 : undefined,
    })

    const [auctionLoading, setAuctionLoading] = useState(true)
    const [productLoading, setProductLoading] = useState(true)
    const [showFilter, setShowFilter] = useState(false)
    const [isRegistered, setIsRegistered] = useState(0)
    const [open, setOpen] = useState(false)
    const [open1, setOpen1] = useState(false)
    const [open2, setOpen2] = useState(false)
    const myRef = useRef(null)
    const [filterValue, setFilterValue] = useState(null)
    const isWeb = useMediaQuery('(min-width:1200px)')
    const [toggleMenu, setToggleMenu] = useState(true)
    const [catClass, setCatClass] = useState(
        new URLSearchParams(window.location.search.substring(1)).get('cat'),
    )
    const [catg] = useState(new URLSearchParams(window.location.search.substring(1)).get('cat'))
    const [rat] = useState(new URLSearchParams(window.location.search.substring(1)).get('rat'))
    const [est] = useState(new URLSearchParams(window.location.search.substring(1)).get('est'))

    const formik = useFormik({
        initialValues: {
            category: [],
            subcategory: [],
        },
    })

    const formik1 = useFormik({
        initialValues: {
            category: catg == 1 || catg == 0 ? '' : catg,
            subcategory: [],
            quality_rating: rat == null || rat == 1 ? [] : rat,
            toprice: est === null || est == 1 ? 0 : est,
        },
    })
    useEffect(() => {
        if (isWeb) {
            setToggleMenu(true)
        } else {
            setToggleMenu(false)
        }
    }, [isWeb])

    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' })

    useEffect(() => {
        getEditAuction({ auctionid: props.match.params.id }, 'auctionView')
        setAuctionLoading(true)
    }, [props.match.params.id, isAuthenticated])

    useEffect(() => {
        getLotList(
            {
                ...subLotSearch,
                ...{
                    sub_cat_id_multiple_io: formik1.values.subcategory,
                    mprice_filter: estimated_arr.filter(
                        (val) => parseInt(val.id) == parseInt(formik1.values.toprice),
                    )[0].value,
                    need_bid_status: 'yes',
                },
            },
            'auctionSubView',
        )
        // if (global?.storeConfigration?.auction_cat_fill?.value) {
        getauctioncat_subcat(
            { lotof: auctionid, site_id: global?.storeDetails?.site_id },
            'auction_sub',
        )
        // }
        setProductLoading(true)
    }, [subLotSearch, isAuthenticated])

    useEffect(() => {
        if (edit_auction.from === 'auctionView') {
            if (edit_auction.auction_details) {
                setAuctionData(edit_auction.auction_details)
                setMinMaxDate(edit_auction.min_max_date_closed)
                setIsRegistered(edit_auction.is_registered)
                if (window.location.href.includes('?cat=1')) {
                    setOpen2(true)
                }
            }
            setTimeout(() => {
                setAuctionLoading(false)
            }, 1500)
        }
    }, [edit_auction])

    const handlePage = (pageNumber) => {
        document.getElementById('sublot').scrollIntoView()
        setSubLotSearch({
            ...subLotSearch,
            ...{
                cat_id_multiple_io:
                    formik1.values.category == undefined || formik1.values.category == ''
                        ? undefined
                        : formik1.values.category,
                sub_cat_id_multiple_io: formik1.values.subcategory,
                mprice_filter: estimated_arr.filter(
                    (val) => parseInt(val.id) == parseInt(formik1.values.toprice),
                )[0].value,
                page: pageNumber,
            },
        })
    }

    const handlefilter = (val) => {
        // getLotList(
        //     {
        //         ...subLotSearch,
        //         ...{
        //             cat_id_multiple_io: formik1.values.category,
        //             sub_cat_id_multiple_io: formik1.values.subcategory,
        //             mprice_filter: estimated_arr.filter(
        //                 (val) => parseInt(val.id) == parseInt(formik1.values.toprice),
        //             )[0].value,
        //             new_order_io: val ? val : 5,
        //         },
        //     },
        //     'auctionSubView',
        // )
        setSubLotSearch({
            ...subLotSearch,
            ...{
                new_order_io: val ? val : 5,
            },
        })
    }

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }

    const handleCat = (id) => {
        let currentUrl = new URL(window.location.href)

        if (currentUrl.searchParams.has('cat')) {
            currentUrl.searchParams.set('cat', id)
            window.history.replaceState({}, '', currentUrl)
        }
        setCatClass(id)
        setOpen2(false)
        formik1.setFieldValue('category', id)
        setSubLotSearch({
            ...subLotSearch,
            ...{
                cat_id_multiple_io:
                    formik1.values.category == undefined || formik1.values.category == ''
                        ? undefined
                        : formik1.values.category,
            },
        })
    }

    const viewProductRef = useRef(subLotData)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = subLotData
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSubLotData,
            type,
        )
    }

    const make_an_offer_update_new = (message, type) => {
        make_an_offer_update_socket_multiple(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSubLotData,
            type,
        )
    }

    const multiauction_handler = (message, type) => {
        var index = viewProductRef.current.findIndex(
            (s) => s.id === parseInt(message.id || message.project_id, 10),
        )
        if (index !== -1) {
            if (parseInt(viewProductRef.current[index].content_head5) == 0) {
                messageHandler(
                    message,
                    viewProductRef.current,
                    userRef.current,
                    setAlert,
                    setSubLotData,
                    type,
                )
            } else {
                if (message.type == 'reverse_auction' && message.status == 'success') {
                    reverse_auction_item(
                        message,
                        viewProductRef.current,
                        userRef.current,
                        setAlert,
                        setSubLotData,
                        'multiple',
                    )
                }
            }
        }
    }

    useEffect(() => {
        if (auctionData?.dynamic_fields?.includes('[{')) {
            var data_obj = {}
            JSON.parse(auctionData.dynamic_fields).map((data) => {
                if (data.name == 'private_auction') {
                    data_obj.private_auction = 0
                    if (data.values.length > 0) {
                        if (data.values.filter((v) => v.selected == 'true').length > 0) {
                            data_obj.private_auction = data.values.filter(
                                (v) => v.selected == 'true',
                            )[0].value
                        }
                    }
                }
            })
            setPrivateauction(parseInt(data_obj.private_auction))
        } else {
            setPrivateauction(0)
        }
    }, [auctionData])

    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            // getLotList(subLotSearch, 'auctionView')
            data.id = data.pid
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            if (global.pluginConfiguration?.reverse_auction?.enable) {
                multiauction_handler(data, 'bidAddtime')
            } else {
                handler(data, 'bidAddtime')
            }
        })

        socket.on('cancelbidemitted', (data) => {
            handler(data, 'cancelbidemitted')
        })

        socket.on('make_an_offer_update', (data) => {
            make_an_offer_update_new(data, 'make_an_offer')
        })

        socketAuction.on('bidAddtime', (data) => {
            if (global.pluginConfiguration?.reverse_auction?.enable) {
                multiauction_handler(data, 'bidAddtime')
            } else {
                handler(data, 'bidAddtime')
            }
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                if (global.pluginConfiguration?.reverse_auction?.enable) {
                    multiauction_handler(data, 'bidAddtime')
                } else {
                    handler(data, 'bidAddtime')
                }
            })
            socket.off('cancelbidemitted', (data) => {
                handler(data, 'cancelbidemitted')
            })

            socket.off('make_an_offer_update', (data) => {
                make_an_offer_update_new(data, 'make_an_offer')
            })

            socketAuction.off('bidAddtime', (data) => {
                if (global.pluginConfiguration?.reverse_auction?.enable) {
                    multiauction_handler(data, 'bidAddtime')
                } else {
                    handler(data, 'bidAddtime')
                }
            })
        }
    }, [])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        setFilterValue(params.get('q')?.toLowerCase())
    }, [window.location.search])

    console.log(filterValue, 'query string')

    useEffect(() => {
        setProductLoading(true)

        if (search_allauctionproducts.from === 'auctionSubView') {
            if (filterValue != (null || undefined)) {
                const filteredData = search_allauctionproducts.results.filter((val) =>
                    val.title?.toLowerCase().includes(filterValue),
                )

                console.log(filteredData, 'search by lot')
                setSubLotData(filteredData)
                setSubLotDataCount(filteredData.total_only)
                setTimeout(() => {
                    setProductLoading(false)
                }, 1500)
            } else {
                setSubLotData(search_allauctionproducts.results)
                setSubLotDataCount(search_allauctionproducts.total_only)
                setTimeout(() => {
                    setProductLoading(false)
                }, 1500)
            }
        }
    }, [search_allauctionproducts, filterValue])

    var filtervalues = [
        {
            label: 'Select Category',
            placeholder: 'Select Category',
            type: 'multiselect',
            class: `col-md-3`,
            options: parentcategories.map((data) => {
                return {
                    value: `${data.id}`,
                    show: `${data.name} (${data.cat_count})`,
                }
            }),
            defaultOptionValue: 'Select Category',
            name: 'category',
            formik: formik,
        },
        {
            label: 'Select SubCategory',
            placeholder: 'Select SubCategory',
            type: 'multiselect',
            no_item: 'No Subcategories found',
            class: `col-md-3`,
            options: childcategories.map((data) => {
                return {
                    value: `${data.id}`,
                    show: `${data.name} (${data.cat_count})`,
                }
            }),
            defaultOptionValue: 'Select SubCategory',
            name: 'subcategory',
            formik: formik,
        },
    ]

    const estimated_arr = [
        { id: 0, description: 'Any', value: [0] },
        { id: 1, description: 'Under $20', value: [0, 20] },
        { id: 2, description: '$20-$50', value: [20, 50] },
        { id: 3, description: '$50-$100', value: [50, 100] },
        { id: 4, description: '$100-$500', value: [100, 500] },
        { id: 5, description: 'Above $500', value: [500] },
    ]

    const ratings_arr = [
        {
            id: 0,
            description: 'New',
            value: 'New',
        },
        {
            id: 1,
            description: 'Powers on',
            value: 'Powers On',
        },
        {
            id: 2,
            description: 'Original Box',
            value: 'Original Box',
        },
        {
            id: 3,
            description: 'As Is Parts Only',
            value: 'As Is Parts Only',
        },
        {
            id: 4,
            description: 'NON RETURNABLE ITEM',
            value: 'NON RETURNABLE ITEM',
        },
    ]

    var filterValues1 = [
        {
            title: 'Category',
            type: 'check',
            name: 'category',
            options: parentcategories.map((data) => {
                return {
                    value: `${data.id}`,
                    show: data.name,
                    className: data.id == catClass ? 'active' : '',
                    description: `${data.name} (${data.cat_count})`,
                    onChange: (e) => {
                        return handleCat(e)
                    },
                }
            }),
            formik: formik,
        },
        // {
        //     title: 'Sub category',
        //     type: 'check',
        //     name: 'subcategory',
        //     options: childcategories.map((data) => {
        //         return {
        //             value: `${data.id}`,
        //             show: data.name,
        //             description: `${data.name} (${data.cat_count})`,
        //         }
        //     }),
        //     formik: formik1,
        // },
        {
            title: 'Condition',
            type: 'check',
            noLabel: true,
            name: 'quality_rating',
            int: 1,
            class: 'col-12',
            options: global.condition_data.map((item) => {
                return {
                    ...item,
                    description: item.condition,
                }
            }),
            formik: formik1,
        },
        {
            title: 'Estimated Retail',
            type: 'radio',
            noLabel: true,
            name: 'toprice',
            int: 1,
            class: 'col-12',
            item: estimated_arr,
            formik: formik1,
        },
    ]

    // useEffect(() => {
    //     if (formik.values.category.length > 0 || formik.values.subcategory.length > 0) {
    //         getLotList(
    //             {
    //                 ...subLotSearch,
    //                 ...{
    //                     cat_id_multiple_io: formik.values.category,
    //                     sub_cat_id_multiple_io: formik.values.subcategory,
    //                     need_bid_status: 'yes',
    //                 },
    //             },
    //             'auctionSubView',
    //         )
    //     }
    // }, [formik.values.category, formik.values.subcategory])

    useEffect(() => {
        let currentUrl = new URL(window.location.href)

        if (currentUrl.searchParams.has('rat')) {
            currentUrl.searchParams.set('rat', formik1.values.quality_rating)
            window.history.replaceState({}, '', currentUrl)
        }
        if (currentUrl.searchParams.has('est')) {
            currentUrl.searchParams.set('est', formik1.values.toprice)
            window.history.replaceState({}, '', currentUrl)
        }
        setSubLotSearch({
            ...subLotSearch,
            ...{
                cat_id_multiple_io:
                    formik1.values.category == '' || formik1.values.category == undefined
                        ? undefined
                        : formik1.values.category,
                sub_cat_id_multiple_io: formik1.values.subcategory,
                mprice_filter: estimated_arr.filter(
                    (val) => parseInt(val.id) == parseInt(formik1.values.toprice),
                )[0].value,
                rating_multiple_io: global.condition_data
                    .filter((item) => formik1.values.quality_rating.includes(item.id.toString()))
                    .map((item) => item.condition),
                need_bid_status: 'yes',
            },
        })
    }, [
        formik1.values.category,
        formik1.values.subcategory,
        formik1.values.toprice,
        formik1.values.quality_rating,
    ])

    useEffect(() => {
        if (auc_cat_value?.auc_cat.length > 0) {
            setParentCategories(auc_cat_value.auc_cat)
        } else {
            setParentCategories([])
        }
        if (auc_cat_value?.auc_sub_cat.length > 0) {
            setChildcategories(auc_cat_value.auc_sub_cat)
        } else {
            setChildcategories([])
        }
    }, [auc_cat_value])

    const showfilter = () => {
        setShowFilter(!showFilter)
    }

    return (
        <Layout props={props}>
            <UserActivity page="auction_view_page" />
            <div className="customContainer pt-3 auctionCnt mb-5">
                <h4 className="av-filter-toggler" onClick={() => setToggleMenu(!toggleMenu)}>
                    <img src="/lots-images/ion_filter.svg" alt="" />
                    Filters
                </h4>
                <div className="auction-details">
                    <div
                        className={`auction-filter ${toggleMenu ? 'show-af-menu' : 'hide-af-menu'}`}
                    >
                        <FilterPanel
                            filterValues={filterValues1}
                            formik={formik1}
                            // onChange={handleCat}
                        />
                    </div>
                    <div className="auction-wrapper">
                        {filterValue == (null || undefined) && (
                            <>
                                {auctionLoading ? (
                                    <Loaders
                                        name="product_view"
                                        isLoading={auctionLoading}
                                        loop={1}
                                    />
                                ) : (
                                    <>
                                        <ProductAuctionTop
                                            auctionData={auctionData}
                                            product_id={auctionid}
                                            executeScroll={executeScroll}
                                            minMaxDateClosed={minMaxDate}
                                            setUpcomming={setUpcomming}
                                            is_registered={isRegistered}
                                            setIsRegistered={setIsRegistered}
                                            auction_id={props.match.params.id}
                                        />
                                        <div ref={myRef}></div>
                                    </>
                                )}
                                <div className="av-details-tabs">
                                    <a onClick={() => setOpen(true)}>
                                        <img src="/lots-images/date.svg" alt="" />
                                        Dates & Times
                                    </a>
                                    <a onClick={() => setOpen1(true)}>
                                        <img src="/lots-images/terms.svg" alt="" />
                                        Terms & Conditions
                                    </a>
                                    <a onClick={() => setOpen2(true)}>
                                        <img src="/lots-images/categories.svg" alt="" />
                                        Categories
                                    </a>
                                </div>
                            </>
                        )}

                        <div className="rpp-wrap filters-av align-items-start">
                            <div
                                className="d-flex align-items-center justify-content-start flex-wrap"
                                style={{ gap: '12px', width: 'calc(100% - 230px)' }}
                            >
                                {showFilter ? (
                                    <>
                                        {formik1.values.category === '' && (
                                            <select
                                                onChange={(e) => handlefilter(e.target.value)}
                                                value={subLotSearch.new_order_io}
                                                name="categories"
                                            >
                                                <option value="">Categories</option>
                                                <option value={1}>Categories A to Z</option>
                                                <option value={2}>Categories Z to A</option>
                                            </select>
                                        )}
                                        <select
                                            onChange={(e) => handlefilter(e.target.value)}
                                            value={subLotSearch.new_order_io}
                                        >
                                            <option value="">Bid Count</option>
                                            <option value={3}>Bid Count Asc</option>
                                            <option value={4}>Bid Count Desc</option>
                                        </select>
                                        <select
                                            onChange={(e) => handlefilter(e.target.value)}
                                            value={subLotSearch.new_order_io}
                                        >
                                            <option value="">End Date</option>
                                            <option value={5}>End Date Asc</option>
                                            <option value={6}>End Date Desc</option>
                                        </select>
                                        <select
                                            onChange={(e) => handlefilter(e.target.value)}
                                            value={subLotSearch.new_order_io}
                                        >
                                            <option value="">Title</option>
                                            <option value={7}>Title Asc</option>
                                            <option value={8}>Title Desc</option>
                                        </select>
                                        <select
                                            onChange={(e) => handlefilter(e.target.value)}
                                            value={subLotSearch.new_order_io}
                                        >
                                            <option value="">Lot Number</option>
                                            <option value={9}>Lot Number Asc</option>
                                            <option value={10}>Lot Number Desc</option>
                                        </select>
                                        <select
                                            onChange={(e) => handlefilter(e.target.value)}
                                            value={subLotSearch.new_order_io}
                                        >
                                            <option value="">Current Bid</option>
                                            <option value={11}>Current Bid Asc</option>
                                            <option value={12}>Current Bid Desc</option>
                                        </select>
                                        <a className="clear" onClick={(e) => handlefilter(5)}>
                                            Clear <i className="material-icons">close</i>
                                        </a>
                                    </>
                                ) : (
                                    ''
                                )}
                            </div>
                            <a onClick={(e) => showfilter()} className="advance-settings">
                                Advance Setting
                                <img src="/lots-images/advanced-settings.svg" alt="" />
                            </a>
                        </div>
                        <hr />

                        {productLoading ? (
                            <Loaders name="table" isLoading={productLoading} loop={1} />
                        ) : (
                            <>
                                {global?.storeConfigration?.auction_cat_fill?.value ? (
                                    <div className="row mt-5">{mapData(filtervalues)}</div>
                                ) : (
                                    ''
                                )}

                                <SubLots
                                    selectedPostion={handleClick}
                                    handleClick={handleClick}
                                    title={auctionData.title}
                                    auctionid={auctionid}
                                    lotData={subLotData}
                                    subLotDataCount={subLotDataCount}
                                    subLotSearch={subLotSearch}
                                    setSubLotSearch={setSubLotSearch}
                                    showviews={true}
                                    setSubLotData={setSubLotData}
                                    audio={audio}
                                    upcomming={upcomming}
                                    private_auction={private_auction}
                                    is_registered={isRegistered}
                                    auction_city={auctionData.city}
                                />
                                <SlidePanel
                                    type="right"
                                    selectedLot={selectedProduct}
                                    handleClose={handleClose}
                                    from={''}
                                    getAllProducts={() => false}
                                    audio={audio}
                                    enableZoomOnHover={true}
                                    private_auction={private_auction}
                                    is_registered={isRegistered}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
            <DateTime open={open} auctionData={auctionData} handleClose={() => setOpen(false)} />
            <AuctionTerms
                open={open1}
                auctionData={auctionData}
                handleClose={() => setOpen1(false)}
            />
            <CategoryModal
                open={open2}
                lotof={auctionData.id}
                handleClick={(cat) => handleCat(cat)}
                handleClose={() => {
                    // if (window.location.href.includes('?cat=1')) {
                    //     history.replace('/search/product-buyer-auction/' + props.match.params.id)
                    // }
                    setOpen2(false)
                }}
            />
        </Layout>
    )
}

export default ProductAuctionComponent
